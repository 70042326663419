import { TokenListURLMap } from '@/types/TokenList';

const tokenlists: TokenListURLMap = {
  Balancer: {
    Default: '',
    Vetted: '',
  },
  External: [],
};

export default tokenlists;
