import { Keys } from '../types';

const keys: Keys = {
  infura: '7d985bb572794bb880815a3f2c5b9114',
  alchemy: 'Ed3kqHyvoIVA8bXDfjc2m-OM64UqbPZP',
  graph: '7c7b4c36244b0b86171a3931eaf9bb23',
  balancerApi: 'da2-7a3ukmnw7bexndpx5x522uafui',
};

export default keys;
